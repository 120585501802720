import React, { createContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { Construct48Balls, Construct49Balls } from "../HubHandlers/Utils";
import {
  HandleBetTime,
  HandleDrawNumber,
  HandleHistoryResults,
  HandleHubJoin,
  HandleReloadGame,
  HandleRoundStart,
  HandleRoundStatus,
} from "../HubHandlers/Handlers";
import ResultsComponent from "./ResultsComponent";
import DrawingComponent from "./DrawingComponent";

export const GeneralContext = createContext(null);
const RootComponent = (props) => {
  const qParams = new URLSearchParams(window.location.search);
  const [connection, setConnection] = useState(null);

  const [gameKer, setGameKer] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [ballsArray, setBallsArray] = useState(null);

  const [switchScreen, setSwitchScreen] = useState(false);

  const [hubJoinedMsg, setHubJoinedMsg] = useState(null);
  const [drawNumberMsg, setDrawNumberMsg] = useState(null);

  const [roundNumber, setRoundNumber] = useState(null);
  const [jackpotCode, setJackpotCode] = useState(null);
  const [jackpotValue, setJackpotValue] = useState(null);
  const [betTimeRemains, setBetTimeRemains] = useState(null);
  const [historyResult, setHistoryResult] = useState(null);

  const [lastMessageArrivedTime, setLastMessageArrivedTime] = useState(
    new Date()
  );
  const [timeKeeper, setTimeKeeper] = useState(new Date());

  const [roundStartMsg, setRoundStartMsg] = useState(null);

  useEffect(() => {
    if (roundStartMsg != null) {
      setJackpotCode(roundStartMsg.JackpotInfo.Code);
      setJackpotValue(roundStartMsg.JackpotInfo.Value);
    }
  }, [roundStartMsg]);

  useEffect(() => {
    const newConn = new signalR.HubConnectionBuilder()
      .withUrl("https://clv-sixgame.syncrobet.ro/gamehub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return "My Access Token";
        },
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConn);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setTimeKeeper(new Date());
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    let xx = qParams.get("ver");
    let yy = qParams.get("id");
    if (xx != null) setGameKer(xx);
    else setGameKer("48");
    if (yy != null) setShopId(yy);
    else setShopId("A02CCA32-1867-44A3-ACDB-241EB3AAF90F");
  }, []);

  useEffect(() => {
    if (gameKer == "48") {
      let yy = Construct48Balls();
      setBallsArray(yy);
    } else if (gameKer == "49") {
      let yyy = Construct49Balls();
      setBallsArray(yyy);
    }
  }, [gameKer]);

  useEffect(() => {
    connection &&
      connection
        .start()
        .then(() => {
          connection.invoke("join", shopId).catch((err) => {
            return console.error(err.toString());
          });
        })
        .then(() => {
          connection.on("hubJoined", (message) =>
            HandleHubJoin(message, setHubJoinedMsg, setLastMessageArrivedTime)
          );
        })
        .then(() => {
          connection.on("drawNumber", (message) => {
            HandleDrawNumber(
              message,
              setDrawNumberMsg,
              setLastMessageArrivedTime
            );
          });
        })
        .then(() => {
          connection.on("roundStatus", (message) => {
            HandleRoundStatus(
              message,
              setDrawNumberMsg,
              setSwitchScreen,
              setLastMessageArrivedTime
            );
          });
        })
        .then(() => {
          connection.on("betTimeRemains", (message) => {
            HandleBetTime(
              message,
              setBetTimeRemains,
              setLastMessageArrivedTime
            );
          });
        })
        .then(() => {
          connection.on("historyResults", (message) => {
            HandleHistoryResults(
              message,
              setHistoryResult,
              setLastMessageArrivedTime
            );
          });
        })
        .then(() => {
          connection.on("roundStart", (message) => {
            HandleRoundStart(
              message,
              setRoundStartMsg,
              setLastMessageArrivedTime
            );
          });
        })
        .then(() => {
          connection.on("reloadGame", (message) => {
            HandleReloadGame(message);
          });
        });

    return () => {
      setConnection(null);
    };
  }, [connection]);

  useEffect(() => {
    if (betTimeRemains < 11) setSwitchScreen(true);
    if (betTimeRemains >= 11) setSwitchScreen(false);
  }, [betTimeRemains]);

  useEffect(() => {
    if (hubJoinedMsg != null) {
      setRoundNumber(hubJoinedMsg.RoundNumber);
      setJackpotCode(hubJoinedMsg.RoundInfo.JackpotInfo.Code);
      setJackpotValue(hubJoinedMsg.RoundInfo.JackpotInfo.Value);
      setHistoryResult(hubJoinedMsg.HistoryResults[0]);
    }
  }, [hubJoinedMsg]);

  useEffect(() => {
    if (drawNumberMsg != null) {
      setRoundNumber(drawNumberMsg.RoundNumber);
      setJackpotCode(drawNumberMsg.JackpotCode);
      setSwitchScreen(true);
    }
  }, [drawNumberMsg]);

  const contextValues = {
    ballsArray: [ballsArray, setBallsArray],

    roundNumber: [roundNumber, setRoundNumber],
    jackpotCode: [jackpotCode, setJackpotCode],
    jackpotValue: [jackpotValue, setJackpotValue],
    betTimeRemains: [betTimeRemains, setBetTimeRemains],
    historyResult: [historyResult, setHistoryResult],

    drawNumberMsg: [drawNumberMsg, setDrawNumberMsg],
    gameKer: [gameKer, setGameKer],
  };

  useEffect(() => {
    if (historyResult) {
      setRoundNumber(historyResult.RoundNumber);
      setJackpotCode(historyResult.JackpotCode);
    }
  }, [historyResult]);

  const RenderCondition = () => {
    if (switchScreen === true) {
      return <DrawingComponent />;
    } else return <ResultsComponent />;
  };

  // useEffect(() => {
  //   if (lastMessageArrivedTime != null) {
  //     let current = new Date();
  //     const difference = Math.abs(current - lastMessageArrivedTime);
  //     console.log(difference);
  //   }
  // }, [lastMessageArrivedTime]);

  useEffect(() => {
    if (lastMessageArrivedTime != null) {
      const diff = Math.abs(timeKeeper - lastMessageArrivedTime) / 1000;
      if (diff >= 15) window.location.reload();
    }
  }, [timeKeeper]);

  return (
    ballsArray && (
      <GeneralContext.Provider value={contextValues}>
        {RenderCondition()}
      </GeneralContext.Provider>
    )
  );
};

export default RootComponent;
