export const HandleHubJoin = (message, setter, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
  let x = new Date();
  lastTime(x);
};
export const HandleBetTime = (message, setter, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
  let x = new Date();
  lastTime(x);
};
export const HandleDrawNumber = (message, setter, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
  let x = new Date();
  lastTime(x);
};
export const HandleRoundStatus = (message, setter, setSwitch, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  if (msg == "Opened") {
    setter(null);
    setSwitch(false);
  }
  let x = new Date();
  lastTime(x);
};
export const HandleHistoryResults = (message, setter, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg)[0]);
  let x = new Date();
  lastTime(x);
};
export const HandleRoundStart = (message, setter, lastTime) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  console.log(JSON.parse(msg));
  setter(JSON.parse(msg));
  let x = new Date();
  lastTime(x);
};

export const HandleReloadGame = (message) => {
  window.location.reload();
};
