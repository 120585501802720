import React, { useContext, useEffect, useState } from "react";
import {
  fromSecToMins,
  HelpEvenOddLabel,
  HelpEvenOddValue,
  HelpFirstBallColorV2,
  HelpFirstBallLabel,
  HelpSumLabel,
  HelpUnderOverFirstBall,
} from "../HubHandlers/Utils";
import { GeneralContext } from "./RootComponent";

const ResultsComponent = (props) => {
  const consumer = useContext(GeneralContext);

  const [jackpotValueCss, setJackpotValueCss] = useState(false);
  const [resValues, setResValues] = useState(null);
  const [trifoiValues, setTrifoiValues] = useState(null);
  const [fbc, setFbc] = useState(null);

  setTimeout(() => {
    setJackpotValueCss(!jackpotValueCss);
  }, 1000);

  useEffect(() => {
    if (consumer.historyResult[0]) {
      setTrifoiValues(consumer.historyResult[0].LuckySlots.split(","));
      setResValues(consumer.historyResult[0].RoundResults.split(","));
    }
  }, [consumer.historyResult[0]]);

  useEffect(() => {
    if (resValues)
      if (consumer.gameKer[0] == "49") {
        let yy = resValues[0] % 7;
        if (yy === 1) {
          setFbc("Blue");
        }
        if (yy === 2) {
          setFbc("Violet");
        }
        if (yy === 3) {
          setFbc("Red");
        }
        if (yy === 4) {
          setFbc("Green");
        }
        if (yy === 5) {
          setFbc("Yellow");
        }
        if (yy === 6) {
          setFbc("Orange");
        }
        if (yy === 0) {
          setFbc("Black");
        }
      } else {
        setFbc(consumer.historyResult[0].FirstBallColor);
      }
  }, [resValues]);

  return (
    resValues &&
    consumer.ballsArray[0][30].color && (
      <div className="results_component_root">
        <div
          className={
            jackpotValueCss
              ? "results_jackpot_value"
              : "results_jackpot_value jackGreen"
          }
        >
          {consumer.jackpotValue[0]}
        </div>
        <div className="results_bonus_img">
          <img src="./baterie.png" />
        </div>
        <div className="results_bonus_img2">
          <img src="./bateriegol2.png" />
        </div>
        <div className="results_bonus_img3">
          <img src="./bateriegol3.png" />
        </div>

        <div className="results_round_number">{consumer.roundNumber[0]}</div>
        <div className="results_jackpot_code">{consumer.jackpotCode[0]}</div>
        <div className="results_bet_time_remains">
          {fromSecToMins(consumer.betTimeRemains[0])}
        </div>

        <>
          <div className="results_ball_1">
            <img src={consumer.ballsArray[0][resValues[0] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[0] - 1]?.value}</div>
          </div>
          <div className="results_ball_2">
            <img src={consumer.ballsArray[0][resValues[1] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[1] - 1]?.value}</div>
          </div>
          <div className="results_ball_3">
            <img src={consumer.ballsArray[0][resValues[2] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[2] - 1]?.value}</div>
          </div>
          <div className="results_ball_4">
            <img src={consumer.ballsArray[0][resValues[3] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[3] - 1]?.value}</div>
          </div>
          <div className="results_ball_5">
            <img src={consumer.ballsArray[0][resValues[4] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[4] - 1]?.value}</div>
          </div>
        </>

        <>
          <div className="span_sum_first_five">
            {consumer.historyResult[0].SumFirstFive}
          </div>
          <div className="results_header_trifoi">
            <img src="./trifoi.png" />
          </div>
          <div className="results_trifoi_1">
            <img
              src={
                consumer.ballsArray[0][
                  resValues[parseInt(trifoiValues[0]) - 1] - 1
                ].color
              }
            />
            <div>
              {
                consumer.ballsArray[0][
                  resValues[parseInt(trifoiValues[0]) - 1] - 1
                ].value
              }
            </div>
          </div>
          <div className="results_trifoi_2">
            <img
              src={
                consumer.ballsArray[0][
                  resValues[parseInt(trifoiValues[1]) - 1] - 1
                ].color
              }
            />
            <div>
              {
                consumer.ballsArray[0][
                  resValues[parseInt(trifoiValues[1]) - 1] - 1
                ].value
              }
            </div>
          </div>
        </>

        <>
          <div className="results_color_value">
            {/* <img
              src={HelpFirstBallColor(consumer.historyResult[0].FirstBallColor)}
            /> */}
            {fbc && <div className={HelpFirstBallColorV2(fbc)}></div>}
          </div>
          <div className="results_color_label">
            {fbc && HelpFirstBallLabel(fbc)}
          </div>

          <div className="results_firstnr_value">
            <img src={consumer.ballsArray[0][resValues[0] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[0] - 1]?.value}</div>
          </div>
          <div className="results_firstnr_label">
            {HelpUnderOverFirstBall(consumer.historyResult[0].FirstBallUO)}
          </div>

          <div className="results_evenodd_value">
            {HelpEvenOddValue(consumer.historyResult[0].FirstBallEO)}
          </div>
          <div className="results_evenodd_label">
            {HelpEvenOddLabel(consumer.historyResult[0].FirstBallEO)}
          </div>

          <div className="results_sum_value">
            {consumer.historyResult[0].SumFirstFive}
          </div>
          <div className="results_sum_label">
            {HelpSumLabel(consumer.historyResult[0].SumFirstFiveUO)}
          </div>
        </>

        <>
          <div className="results_ball_6">
            <img src={consumer.ballsArray[0][resValues[5] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[5] - 1]?.value}</div>
          </div>
          <div className="results_ball_7">
            <img src={consumer.ballsArray[0][resValues[6] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[6] - 1]?.value}</div>
          </div>
          <div className="results_ball_8">
            <img src={consumer.ballsArray[0][resValues[7] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[7] - 1]?.value}</div>
          </div>
          <div className="results_ball_9">
            <img src={consumer.ballsArray[0][resValues[8] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[8] - 1]?.value}</div>
          </div>
          <div className="results_ball_10">
            <img src={consumer.ballsArray[0][resValues[9] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[9] - 1]?.value}</div>
          </div>

          <div className="results_ball_11">
            <img src={consumer.ballsArray[0][resValues[10] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[10] - 1]?.value}</div>
          </div>
          <div className="results_ball_12">
            <img src={consumer.ballsArray[0][resValues[11] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[11] - 1]?.value}</div>
          </div>
          <div className="results_ball_13">
            <img src={consumer.ballsArray[0][resValues[12] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[12] - 1]?.value}</div>
          </div>
          <div className="results_ball_14">
            <img src={consumer.ballsArray[0][resValues[13] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[13] - 1]?.value}</div>
          </div>
          <div className="results_ball_15">
            <img src={consumer.ballsArray[0][resValues[14] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[14] - 1]?.value}</div>
          </div>

          <div className="results_ball_16">
            <img src={consumer.ballsArray[0][resValues[15] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[15] - 1]?.value}</div>
          </div>
          <div className="results_ball_17">
            <img src={consumer.ballsArray[0][resValues[16] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[16] - 1]?.value}</div>
          </div>
          <div className="results_ball_18">
            <img src={consumer.ballsArray[0][resValues[17] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[17] - 1]?.value}</div>
          </div>
          <div className="results_ball_19">
            <img src={consumer.ballsArray[0][resValues[18] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[18] - 1]?.value}</div>
          </div>
          <div className="results_ball_20">
            <img src={consumer.ballsArray[0][resValues[19] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[19] - 1]?.value}</div>
          </div>

          <div className="results_ball_21">
            <img src={consumer.ballsArray[0][resValues[20] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[20] - 1]?.value}</div>
          </div>
          <div className="results_ball_22">
            <img src={consumer.ballsArray[0][resValues[21] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[21] - 1]?.value}</div>
          </div>
          <div className="results_ball_23">
            <img src={consumer.ballsArray[0][resValues[22] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[22] - 1]?.value}</div>
          </div>
          <div className="results_ball_24">
            <img src={consumer.ballsArray[0][resValues[23] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[23] - 1]?.value}</div>
          </div>
          <div className="results_ball_25">
            <img src={consumer.ballsArray[0][resValues[24] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[24] - 1]?.value}</div>
          </div>

          <div className="results_ball_26">
            <img src={consumer.ballsArray[0][resValues[25] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[25] - 1]?.value}</div>
          </div>
          <div className="results_ball_27">
            <img src={consumer.ballsArray[0][resValues[26] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[26] - 1]?.value}</div>
          </div>
          <div className="results_ball_28">
            <img src={consumer.ballsArray[0][resValues[27] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[27] - 1]?.value}</div>
          </div>
          <div className="results_ball_29">
            <img src={consumer.ballsArray[0][resValues[28] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[28] - 1]?.value}</div>
          </div>
          <div className="results_ball_30">
            <img src={consumer.ballsArray[0][resValues[29] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[29] - 1]?.value}</div>
          </div>

          <div className="results_ball_31">
            <img src={consumer.ballsArray[0][resValues[30] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[30] - 1]?.value}</div>
          </div>
          <div className="results_ball_32">
            <img src={consumer.ballsArray[0][resValues[31] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[31] - 1]?.value}</div>
          </div>
          <div className="results_ball_33">
            <img src={consumer.ballsArray[0][resValues[32] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[32] - 1]?.value}</div>
          </div>
          <div className="results_ball_34">
            <img src={consumer.ballsArray[0][resValues[33] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[33] - 1]?.value}</div>
          </div>
          <div className="results_ball_35">
            <img src={consumer.ballsArray[0][resValues[34] - 1]?.color} />
            <div>{consumer.ballsArray[0][resValues[34] - 1]?.value}</div>
          </div>
        </>
        <div className="results_bottom_label">
          Rezultatele extragerii cu numarul {consumer.roundNumber[0]}
        </div>
      </div>
    )
  );
};

export default ResultsComponent;
