import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "./RootComponent";

const DrawingComponent = () => {
  const consumer = useContext(GeneralContext);
  const [jackpotCss, setJackpotCss] = useState(false);

  const [currentBall, setCurrentBall] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [prevNumbers, setPrevNumbers] = useState(null);
  const [lucky, setLucky] = useState(null);

  const [finalMessage, setFinalMessage] = useState(null);

  setTimeout(() => {
    setJackpotCss(!jackpotCss);
  }, 1000);

  useEffect(() => {
    if (consumer.drawNumberMsg[0] != null) {
      setCurrentBall(consumer.drawNumberMsg[0].Number);
      setCurrentIndex(consumer.drawNumberMsg[0].Index);
      setPrevNumbers(consumer.drawNumberMsg[0].PreviousNumbers);
      setLucky(consumer.drawNumberMsg[0].LuckySlots.split(","));
    }
  }, [consumer.drawNumberMsg[0]]);

  useEffect(() => {
    if (prevNumbers != null && prevNumbers.length > 0) {
    }
  }, [prevNumbers]);

  useEffect(() => {
    if (currentBall != null) {
      const id = setTimeout(() => {
        setCurrentBall(null);
      }, 2900);

      return () => {
        clearTimeout(id);
      };
    }
  }, [currentBall]);

  useEffect(() => {
    if (currentIndex >= 33) {
      const id = setTimeout(() => {
        prevNumbers.push(currentBall);
      }, 2900);

      return () => {
        clearTimeout(id);
      };
    }
  }, [currentIndex]);

  useEffect(() => {
    if (prevNumbers) {
      if (prevNumbers.length === 35) {
        setFinalMessage("Runda inchisa");
      }
    }
  }, [prevNumbers]);

  const getSumFirsFive = () => {
    if (prevNumbers != null) {
      let x = 0;
      if (prevNumbers.length >= 5) {
        for (let i = 0; i < 5; i++) x += prevNumbers[i];
      } else if (prevNumbers.length < 5) {
        for (let i = 0; i < prevNumbers.length; i++) x += prevNumbers[i];
      }
      return x;
    } else return "0";
  };

  return (
    <div className="drawing_root_component">
      <div
        className={
          jackpotCss ? "draw_jackpot_value" : "draw_jackpot_value jackGreen"
        }
      >
        {consumer.jackpotValue[0]}
      </div>
      <div className="results_bonus_img">
        <img src="./baterie.png" />
      </div>
      <div className="results_bonus_img2">
        <img src="./bateriegol2.png" />
      </div>
      <div className="results_bonus_img3">
        <img src="./bateriegol3.png" />
      </div>
      <div className="results_round_number_cl">{consumer.roundNumber[0]}</div>
      <div className="results_jackpot_code">{consumer.jackpotCode[0]}</div>

      {currentBall && (
        <div className="current_extracted_ball">
          <img src={consumer.ballsArray[0][currentBall - 1]?.color} />
          <div> {currentBall}</div>
        </div>
      )}

      {consumer.drawNumberMsg[0] === null && (
        <div className="timer_before_round">{consumer.betTimeRemains[0]}</div>
      )}

      <div className="span_sum_first_five">{getSumFirsFive()}</div>
      <div className="results_header_trifoi_draw">
        <img src="./trifoi.png" />
      </div>

      {lucky && (
        <div className={"drawn_ball_" + lucky[0]}>
          <img
            src="./trifoi.png"
            width="63px !important"
            height="63px !important"
          />
        </div>
      )}
      {lucky && (
        <div className={"drawn_ball_" + lucky[1]}>
          <img src="./trifoi.png" width="63px" height="63px" />
        </div>
      )}

      {prevNumbers && prevNumbers.length >= parseInt(lucky[0]) && (
        <div className="results_trifoi_1">
          <img
            src={
              consumer.ballsArray[0][prevNumbers[parseInt(lucky[0]) - 1] - 1]
                .color
            }
          />
          <div>
            {
              consumer.ballsArray[0][prevNumbers[parseInt(lucky[0]) - 1] - 1]
                .value
            }
          </div>
        </div>
      )}
      {prevNumbers && prevNumbers.length >= parseInt(lucky[1]) && (
        <div className="results_trifoi_2">
          <img
            src={
              consumer.ballsArray[0][prevNumbers[parseInt(lucky[1]) - 1] - 1]
                .color
            }
          />
          <div>
            {
              consumer.ballsArray[0][prevNumbers[parseInt(lucky[1]) - 1] - 1]
                .value
            }
          </div>
        </div>
      )}

      <>
        {prevNumbers && prevNumbers.length > 0 && (
          <div className="drawn_ball_1">
            <img src={consumer.ballsArray[0][prevNumbers[0] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[0] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 1 && (
          <div className="drawn_ball_2">
            <img src={consumer.ballsArray[0][prevNumbers[1] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[1] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 2 && (
          <div className="drawn_ball_3">
            <img src={consumer.ballsArray[0][prevNumbers[2] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[2] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 3 && (
          <div className="drawn_ball_4">
            <img src={consumer.ballsArray[0][prevNumbers[3] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[3] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 4 && (
          <div className="drawn_ball_5">
            <img src={consumer.ballsArray[0][prevNumbers[4] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[4] - 1]?.value}</div>
          </div>
        )}

        {prevNumbers && prevNumbers.length > 5 && (
          <div className="drawn_ball_6">
            <img src={consumer.ballsArray[0][prevNumbers[5] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[5] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 6 && (
          <div className="drawn_ball_7">
            <img src={consumer.ballsArray[0][prevNumbers[6] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[6] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 7 && (
          <div className="drawn_ball_8">
            <img src={consumer.ballsArray[0][prevNumbers[7] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[7] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 8 && (
          <div className="drawn_ball_9">
            <img src={consumer.ballsArray[0][prevNumbers[8] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[8] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 9 && (
          <div className="drawn_ball_10">
            <img src={consumer.ballsArray[0][prevNumbers[9] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[9] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 10 && (
          <div className="drawn_ball_11">
            <img src={consumer.ballsArray[0][prevNumbers[10] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[10] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 11 && (
          <div className="drawn_ball_12">
            <img src={consumer.ballsArray[0][prevNumbers[11] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[11] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 12 && (
          <div className="drawn_ball_13">
            <img src={consumer.ballsArray[0][prevNumbers[12] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[12] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 13 && (
          <div className="drawn_ball_14">
            <img src={consumer.ballsArray[0][prevNumbers[13] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[13] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 14 && (
          <div className="drawn_ball_15">
            <img src={consumer.ballsArray[0][prevNumbers[14] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[14] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 15 && (
          <div className="drawn_ball_16">
            <img src={consumer.ballsArray[0][prevNumbers[15] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[15] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 16 && (
          <div className="drawn_ball_17">
            <img src={consumer.ballsArray[0][prevNumbers[16] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[16] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 17 && (
          <div className="drawn_ball_18">
            <img src={consumer.ballsArray[0][prevNumbers[17] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[17] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 18 && (
          <div className="drawn_ball_19">
            <img src={consumer.ballsArray[0][prevNumbers[18] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[18] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 19 && (
          <div className="drawn_ball_20">
            <img src={consumer.ballsArray[0][prevNumbers[19] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[19] - 1]?.value}</div>
          </div>
        )}

        {prevNumbers && prevNumbers.length > 20 && (
          <div className="drawn_ball_21">
            <img src={consumer.ballsArray[0][prevNumbers[20] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[20] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 21 && (
          <div className="drawn_ball_22">
            <img src={consumer.ballsArray[0][prevNumbers[21] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[21] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 22 && (
          <div className="drawn_ball_23">
            <img src={consumer.ballsArray[0][prevNumbers[22] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[22] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 23 && (
          <div className="drawn_ball_24">
            <img src={consumer.ballsArray[0][prevNumbers[23] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[23] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 24 && (
          <div className="drawn_ball_25">
            <img src={consumer.ballsArray[0][prevNumbers[24] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[24] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 25 && (
          <div className="drawn_ball_26">
            <img src={consumer.ballsArray[0][prevNumbers[25] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[25] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 26 && (
          <div className="drawn_ball_27">
            <img src={consumer.ballsArray[0][prevNumbers[26] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[26] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 27 && (
          <div className="drawn_ball_28">
            <img src={consumer.ballsArray[0][prevNumbers[27] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[27] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 28 && (
          <div className="drawn_ball_29">
            <img src={consumer.ballsArray[0][prevNumbers[28] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[28] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 29 && (
          <div className="drawn_ball_30">
            <img src={consumer.ballsArray[0][prevNumbers[29] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[29] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 30 && (
          <div className="drawn_ball_31">
            <img src={consumer.ballsArray[0][prevNumbers[30] - 1].color} />
            <div>{consumer.ballsArray[0][prevNumbers[30] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 31 && (
          <div className="drawn_ball_32">
            <img src={consumer.ballsArray[0][prevNumbers[31] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[31] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 32 && (
          <div className="drawn_ball_33">
            <img src={consumer.ballsArray[0][prevNumbers[32] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[32] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 33 && (
          <div className="drawn_ball_34">
            <img src={consumer.ballsArray[0][prevNumbers[33] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[33] - 1]?.value}</div>
          </div>
        )}
        {prevNumbers && prevNumbers.length > 34 && (
          <div className="drawn_ball_35">
            <img src={consumer.ballsArray[0][prevNumbers[34] - 1]?.color} />
            <div>{consumer.ballsArray[0][prevNumbers[34] - 1]?.value}</div>
          </div>
        )}
      </>

      {finalMessage && <div className="final_message">{finalMessage}</div>}

      <div className="results_bottom_label">
        {consumer.drawNumberMsg[0] === null
          ? "Jocul incepe in " + consumer.betTimeRemains[0] + " secunde"
          : "Jocul a inceput !!!"}
      </div>
    </div>
  );
};

export default DrawingComponent;
